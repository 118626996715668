import * as Sentry from '@sentry/browser';

if (process.env.RAILS_ENV === 'production') {
  const config: Sentry.BrowserOptions = {
    dsn: 'https://55a4385ee51740b08471b532e1b43848@sentry.io/1381549',
    ignoreErrors: [
      /InvalidCharacterError: .*<IFRAME name.*/,
    ],
    denyUrls: [
      /moz-extension:\/\/.*/,
      /google-analytics\.com/,
    ],
  };

  const sentryEnvironment: HTMLMetaElement | null = document.querySelector('meta[name="sentry-environment"]');

  if (sentryEnvironment && sentryEnvironment.content) {
    config.environment = sentryEnvironment.content;
  }

  Sentry.init(config);

  Sentry.configureScope((scope) => {
    const userId: HTMLMetaElement | null = document.querySelector('meta[name="current-user"]');
    if (userId && userId.content) {
      scope.setUser({ id: userId.content });
    }

    const clientId: HTMLMetaElement | null = document.querySelector('meta[name="current-client"]');
    if (clientId && clientId.content) {
      scope.setTag('client_id', clientId.content);
    }
  });
}
